import { RouteSheetsTransitList, RouteTemplate } from '@core/interfaces/route-sheets-transit.interface';

export class EstimateRouteBetweenPharmacies {
  static readonly type = '[EstimateRouteBetweenPharmacies] Estimate Route Between Pharmacies';

  constructor(public data: RouteTemplate) {}
}

export class GetRouteSheetTransitList {
  static readonly type = '[GetRouteSheetTransitList] Get Route Sheet Transit List';

  constructor() {}
}

export class ArchiveRouteSheetTransit {
  static readonly type = '[ArchiveRouteSheetTransit] Archive Route Sheet Transit';

  constructor(public id: string) {}
}

export class CreateRouteSheetTransit {
  static readonly type = '[CreateRouteSheetTransit] Create Route Sheet Transit';

  constructor(public routeSheetTransit: RouteSheetsTransitList) {}
}

export class UpdateRouteSheetTransit {
  static readonly type = '[UpdateRouteSheetTransit] Update Route Sheet Transit';

  constructor(public routeSheetTransit: RouteSheetsTransitList) {}
}
