import { Injectable } from '@angular/core';
import { ApolloQueryResult, FetchResult } from '@apollo/client/core';
import { FetchPolicy } from '@core/enums/fetch-policy-apollo.enum';
import { TransformedVehicleType } from '@core/interfaces/reference-data';
import {
  CreateRouteTemplateDto,
  CreateRouteTemplateEstimation,
  CreateRouteTemplateEstimationMap,
  GetRouteSheetsTransitItemResponse,
  GetRouteSheetsTransitList,
  RouteEstimationResponse,
  RouteSheetsTransitList,
  RouteTemplateCreateResponse,
  RouteTemplateUpdateResponse,
} from '@core/interfaces/route-sheets-transit.interface';
import { ArchiveResponse, VehicleType } from '@core/interfaces/vehicle-info.interface';
import {
  ArchiveRouteSheetTransit,
  CreateRouteSheetTransit,
  EstimateRouteBetweenPharmacies,
  UpdateRouteSheetTransit,
} from '@core/store/action/route-sheets-transit.action';
import { Store } from '@ngxs/store';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouteSheetTransitService {
  constructor(
    private readonly apollo: Apollo,
    private readonly store: Store,
  ) {}

  public getRouteBetweenPharmacies(
    data: CreateRouteTemplateEstimationMap,
  ): Observable<MutationResult<RouteEstimationResponse>> {
    const reauestData: CreateRouteTemplateEstimation = this.mapToGraphQLRequest(data);
    return this.apollo.query<RouteEstimationResponse>({
      query: gql`
        query getRouteBetweenPharmacies($data: RouteTemplateEstimationDto!) {
          getRouteBetweenPharmacies(data: $data) {
            estimatedTime
            estimatedDistance
            timeFrom
            timeTo
            vehicleType {
              id
              speed
            }
            waypointTemplates {
              externalId
              address
              distanceFromPreviousPointOnRoad
              order
              point
              pointOnRoad
              timeFrom
              timeTo
              timeToStay
              transitTime
              type
            }
          }
        }
      `,
      variables: { data: reauestData },
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  private mapToGraphQLRequest(data: CreateRouteTemplateEstimationMap): CreateRouteTemplateEstimation {
    const vehicleSpeed = 'subInfo' in data.vehicleType
      ? (data.vehicleType as TransformedVehicleType).subInfo?.[0]?.speed
      : (data.vehicleType as VehicleType).speed;
    return {
      timeFrom: data.timeFrom,
      vehicleSpeedForEstimation: vehicleSpeed,
      vehicleTypeId: Number(data.vehicleType.id),
      isOrderChanged: data!.isOrderChanged || false,
      waypointTemplates: data.waypointTemplates.map((waypoint) => ({
        externalId: waypoint.externalId,
        order: waypoint.order,
        timeToStay: waypoint.timeToStay,
        transitTime: waypoint.transitTime,
        type: waypoint.type,
      })),
    };
  }

  public getRouteBetweenPharmaciesWithRoute(
    data: CreateRouteTemplateEstimationMap,
  ): Observable<MutationResult<RouteEstimationResponse>> {
    const reauestData: CreateRouteTemplateEstimation = this.mapToGraphQLRequest(data);
    return this.apollo
      .query<RouteEstimationResponse>({
        query: gql`
          query getRouteBetweenPharmacies($data: RouteTemplateEstimationDto!) {
            getRouteBetweenPharmacies(data: $data) {
              estimatedTime
              route {
                coordinates
              }
              timeFrom
              timeTo
              vehicleType {
                id
                speed
              }
              waypointTemplates {
                externalId
                address
                distanceFromPreviousPointOnRoad
                order
                point
                pointOnRoad
                timeFrom
                timeTo
                timeToStay
                transitTime
                type
              }
            }
          }
        `,
        variables: { data: reauestData },
        fetchPolicy: FetchPolicy.NoCache,
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new EstimateRouteBetweenPharmacies(res.data.getRouteBetweenPharmacies));
          }
        }),
      );
  }

  public getListRouteTemplates(): Observable<ApolloQueryResult<{ getListRouteTemplate: RouteSheetsTransitList[] }>> {
    return this.apollo.query<{ getListRouteTemplate: RouteSheetsTransitList[] }>({
      query: gql`
        query getListRouteTemplate {
          getListRouteTemplate {
            id
            name
            canArchive
            canUpdate
            cityId
            timeFrom
            createdAt
            estimatedDistance
            estimatedTime
            isActive
            timeTo
            estimatedDistance
            vehicleSpeedForEstimation
            name
            timeFrom
            timeTo
            estimatedTime
            vehicleTypeId
            waypointTemplates {
              externalId
              order
              timeFrom
              timeTo
              timeToStay
              transitTime
              type
            }
          }
        }
      `,
      fetchPolicy: FetchPolicy.NoCache,
    });
  }

  public createRouteTemplate(data: CreateRouteTemplateDto): Observable<MutationResult<RouteTemplateCreateResponse>> {
    const reauestData: CreateRouteTemplateEstimation = this.mapToGraphQLRequestCreate(data);
    return this.apollo
      .mutate<RouteTemplateCreateResponse>({
        mutation: gql`
          mutation createRouteTemplate($data: CreateRouteTemplateDto!) {
            createRouteTemplate(data: $data) {
              id
              name
              canArchive
              canUpdate
              cityId
              timeFrom
              timeTo
              estimatedDistance
              estimatedTime
              isActive
              vehicleTypeId
              waypointTemplates {
                externalId
                order
                timeFrom
                timeTo
                timeToStay
                transitTime
                type
              }
            }
          }
        `,
        variables: {
          data: reauestData,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new CreateRouteSheetTransit(res.data.createRouteTemplate));
          }
        }),
      );
  }

  public updateRouteTemplate(
    data: CreateRouteTemplateDto,
    id: number,
  ): Observable<MutationResult<RouteTemplateUpdateResponse>> {
    const reauestData: CreateRouteTemplateEstimation = this.mapToGraphQLRequestCreate(data);
    return this.apollo
      .mutate<RouteTemplateUpdateResponse>({
        mutation: gql`
          mutation updateRouteTemplate($data: UpdateRouteTemplateDto!, $id: Float!) {
            updateRouteTemplate(data: $data, id: $id) {
              id
              name
              canArchive
              canUpdate
              cityId
              timeFrom
              timeTo
              estimatedDistance
              estimatedTime
              isActive
              vehicleTypeId
              waypointTemplates {
                externalId
                order
                timeFrom
                timeTo
                timeToStay
                transitTime
                type
              }
            }
          }
        `,
        variables: {
          data: reauestData,
          id,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new UpdateRouteSheetTransit(res.data.updateRouteTemplate));
          }
        }),
      );
  }

  private mapToGraphQLRequestCreate(data: CreateRouteTemplateDto): CreateRouteTemplateDto {
    return {
      isActive: data.isActive,
      isOrderChanged: data.isOrderChanged,
      timeFrom: data.timeFrom,
      vehicleSpeedForEstimation: data.vehicleSpeedForEstimation,
      vehicleTypeId: data.vehicleTypeId,
      name: data.name,
      waypointTemplates: data.waypointTemplates.map((waypoint) => ({
        externalId: waypoint.externalId,
        order: waypoint.order,
        timeToStay: waypoint.timeToStay,
        transitTime: waypoint.transitTime,
        type: waypoint.type,
      })),
    };
  }

  public archiveRouteSheetTransit(id: number): Observable<FetchResult<{ archiveRouteTemplate: ArchiveResponse }>> {
    return this.apollo
      .mutate<{ archiveRouteTemplate: ArchiveResponse }>({
        mutation: gql`
          mutation archiveRouteTemplate($id: Float!) {
            archiveRouteTemplate(id: $id) {
              id
            }
          }
        `,
        variables: {
          id,
        },
      })
      .pipe(
        tap((res) => {
          if (res && res.data) {
            this.store.dispatch(new ArchiveRouteSheetTransit(res.data.archiveRouteTemplate.id));
          }
        }),
      );
  }

  public getRouteTemplate(id: number): Observable<MutationResult<GetRouteSheetsTransitItemResponse>> {
    return this.apollo
      .query<GetRouteSheetsTransitItemResponse>({
        query: gql`
          query getRouteTemplate($id: Float!) {
            getRouteTemplate(id: $id) {
              estimatedTime
              name
              estimatedDistance
              timeFrom
              isActive
              timeTo
              route {
                coordinates
              }
              vehicleType {
                id
                speed
              }
              waypointTemplates {
                externalId
                address
                distanceFromPreviousPointOnRoad
                order
                point
                pointOnRoad
                timeFrom
                timeTo
                timeToStay
                transitTime
                type
              }
            }
          }
        `,
        variables: { id },
        fetchPolicy: FetchPolicy.NoCache,
      })
      .pipe(
        tap((res) => {
          const updatedRouteTemplate = {
            ...res.data.getRouteTemplate,
            timeFrom: res.data.getRouteTemplate.timeFrom?.slice(0, -3),
          };
          if (res && res.data) {
            this.store.dispatch(new EstimateRouteBetweenPharmacies(updatedRouteTemplate));
          }
        }),
      );
  }
}
