import { Injectable } from '@angular/core';
import {
  Action, Selector, State, StateContext,
} from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { RouteSheetTransitService } from '@core/services/route-sheet-transit.service';
import {
  CreateRouteTemplateEstimation,
  RouteEstimationResponse,
  RouteSheetsTransitList,
  RouteTemplate,
} from '@core/interfaces/route-sheets-transit.interface';
import { MutationResult } from 'apollo-angular';
import {
  ArchiveRouteSheetTransit,
  CreateRouteSheetTransit,
  EstimateRouteBetweenPharmacies,
  GetRouteSheetTransitList,
  UpdateRouteSheetTransit,
} from '../action/route-sheets-transit.action';

export interface RouteSheetsTransitStateModel {
  routeSheetsTransitList: RouteSheetsTransitList[];
  routeSheetTransitItem: RouteTemplate | null;
}

@State<RouteSheetsTransitStateModel>({
  name: 'RouteSheetsTransitState',
  defaults: {
    routeSheetsTransitList: [],
    routeSheetTransitItem: null,
  },
})
@Injectable()
export class RouteSheetsTransitState {
  constructor(private routeSheetTransitService: RouteSheetTransitService) {}

  @Selector()
  static getRouteSheetsTransitList(state: RouteSheetsTransitStateModel): RouteSheetsTransitList[] {
    return state.routeSheetsTransitList;
  }

  @Selector()
  static getRouteSheetTransitItem(state: RouteSheetsTransitStateModel): RouteTemplate | null {
    return state.routeSheetTransitItem;
  }

  // Todo cCheck if we can change the architecture of creating this action.
  @Action(EstimateRouteBetweenPharmacies)
  public estimateRouteBetweenPharmacies(
    ctx: StateContext<RouteSheetsTransitStateModel>,
    { data }: EstimateRouteBetweenPharmacies,
  ): void {
    ctx.patchState({ routeSheetTransitItem: data });
  }

  @Action(CreateRouteSheetTransit)
  public createRouteSheetTransit(
    ctx: StateContext<RouteSheetsTransitStateModel>,
    { routeSheetTransit }: CreateRouteSheetTransit,
  ): void {
    const currentState = ctx.getState();

    const updatedVehicles = [...currentState.routeSheetsTransitList, routeSheetTransit];
    ctx.patchState({
      routeSheetsTransitList: updatedVehicles,
    });
  }

  @Action(UpdateRouteSheetTransit)
  public updateRouteSheetTransit(
    ctx: StateContext<RouteSheetsTransitStateModel>,
    { routeSheetTransit }: UpdateRouteSheetTransit,
  ): void {
    const currentState = ctx.getState();

    const updatedrouteSheetTransit = [...currentState.routeSheetsTransitList];
    const routeSheetTransitIndex = updatedrouteSheetTransit.findIndex((v) => v.id === routeSheetTransit.id);

    if (routeSheetTransitIndex !== -1) {
      updatedrouteSheetTransit[routeSheetTransitIndex] = {
        ...updatedrouteSheetTransit[routeSheetTransitIndex],
        ...routeSheetTransit,
      };
    }

    ctx.patchState({
      routeSheetsTransitList: updatedrouteSheetTransit,
    });
  }

  @Action(GetRouteSheetTransitList)
  public getRouteSheetTransitList(ctx: StateContext<RouteSheetsTransitStateModel>): void {
    this.routeSheetTransitService.getListRouteTemplates().subscribe((res) => {
      ctx.patchState({ routeSheetsTransitList: res.data?.getListRouteTemplate });
    });
  }

  @Action(ArchiveRouteSheetTransit)
  public archiveRouteSheetTransit(
    ctx: StateContext<RouteSheetsTransitStateModel>,
    { id }: ArchiveRouteSheetTransit,
  ): void {
    const currentState = ctx.getState();
    const updatedVehicles = currentState.routeSheetsTransitList.filter(
      (routeSheetsTransit) => routeSheetsTransit.id !== Number(id),
    );
    ctx.patchState({
      routeSheetsTransitList: updatedVehicles,
    });
  }
}
